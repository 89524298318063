@tailwind base;
@tailwind components;
@tailwind utilities;

/* FONTS  */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap");

/* Background Images  */
.NewsLetterBg {
  background-image: url("../.././assets/images/NewsLetter\ and\ Testimonials/NewsLetterBg.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.TestimonialsBg {
  background-image: url("../.././assets/images/NewsLetter\ and\ Testimonials/TestimonialsBg.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

/* Perfect Trip Section Background Images */
.JeddahPerfectTripBg {
  background-image: url("../.././assets/images/Perfect\ Trip/JeddahPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.LahorePerfectTripBg {
  background-image: url("../.././assets/images/Perfect\ Trip/LahorePerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.KarachiPerfectTripBg {
  background-image: url("../.././assets/images/Perfect\ Trip/KarachiPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.NewYorkPerfectTripBg {
  background-image: url("../.././assets/images/Perfect\ Trip/NewYorkPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.IstanbulPerfectTripBg {
  background-image: url("../.././assets/images/Perfect\ Trip/IstanbulPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.MilanPerfectTripBg {
  background-image: url("../.././assets/images/Perfect\ Trip/MilanPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.DubaiPerfectTripBg {
  background-image: url(".../.././assets/images/Perfect\ Trip/DubaiPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.EgyptPerfectTripBg {
  background-image: url(".../.././assets/images/Perfect\ Trip/EgyptPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}
.JapanPerfectTripBg {
  background-image: url(".../.././assets/images/Perfect\ Trip/JapanPerfectTrip.webp") !important;
  background-position: center !important;
  background-size: cover !important;
}

@media screen and (min-width: 1280px) {
  .Tabs {
    width: 800px !important;
  }
}

@media screen and (min-width: 1280px) {
  .Tabs {
    width: 800px !important;
  }
}

.zokiTextColor {
  color: #b99956 !important;
}
.zokiBtnColor {
  background: #bd9f5a !important;
}
.zokiBgColor {
  background: #e4cb83 !important;
}
.zokiHeadings {
  font-family: "Playfair Display", serif !important;
  font-weight: bolder !important;
}
.boldText {
  font-weight: bolder !important;
}
.capitalizedText {
  text-transform: capitalize !important;
}
.zokiTextSm {
  font-size: 12px !important;
}
.zokiTextBlack {
  color: #100e0c !important;
  text-decoration: none !important;
}
.zokiBgBlack {
  background-color: #100e0c !important;
}
.SingleUniqueTextAtAdventures {
  font-family: "Covered By Your Grace", cursive !important;
  font-weight: bolder !important;
}
